import './App.css';
import uc from '../src/assets/images/uc.gif'


function App() {
  return (
    <div className="App">
      <h1> Under Construction !!!</h1>
     <img src = {uc} alt = "Penguin under construction" ></img>     
    </div>
  );
}

export default App;
